@use 'base/variables' as v;
@use 'base/mixins' as m;

.servicios {
    background-color: v.$grismasoscuro;
    padding: 5rem 0;

    h3, p {
        text-align: center;
        color: v.$blanco;
    }
    .grupo {
        text-transform: uppercase;
        margin: 1rem 0;
    }
}
.servicios-contenedor {
    @include m.tablet {
        @include m.grid(2, 0.5rem);
    }
    @include m.desktop {
        @include m.grid(3, 0.5rem);
    }
    p {
        color: v.$blanco;
    }
    
}

.servicio {
    // margin-bottom: 2rem;
    margin: 1rem 1rem 1rem 1rem;
    padding: 1rem;
    opacity: 0.7;
    transition: opacity 0.5s ease-in-out;
            
    h2 {
         
        &:after {
            content: '';
            display: block;
            width: 30rem;
            height: 30rem;
            background-size: 30rem;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 2rem auto 0 auto;
            
        }
       
    } &:hover {
        cursor: pointer;
        transition: opacity 0.5s ease-in-out;
        opacity: 1.0;
   }
}
.calidad-de-aire .nombre-servicio:after {
    background-image: url(../img/calidad-de-aire.png);
    }
.efluente .nombre-servicio:after {
    background-image: url(../img/efluente.png);
}
.agua .nombre-servicio:after {
    background-image: url(../img/agua.png);
}
.suelo .nombre-servicio:after {
    background-image: url(../img/suelo.png);
}
.emision .nombre-servicio:after {
    background-image: url(../img/emision.png);
}
.ruido-ambiental .nombre-servicio:after {
    background-image: url(../img/ruido-ambiental.png);
}
.aire-laboral .nombre-servicio:after {
    background-image: url(../img/aire-laboral.png);
}
.ruido-laboral .nombre-servicio:after {
    background-image: url(../img/ruido-laboral.png);
}
.ergonomia .nombre-servicio:after {
    background-image: url(../img/ergonomia.png);
}
.pat .nombre-servicio:after {
    background-image: url(../img/pat.png);
}
.iluminacion .nombre-servicio:after {
    background-image: url(../img/iluminacion.png);
}
.vibraciones .nombre-servicio:after {
    background-image: url(../img/vibraciones.png);
}
.carga-termica .nombre-servicio:after {
    background-image: url(../img/carga-termica.png);
}
.radiacion-uv .nombre-servicio:after {
    background-image: url(../img/radiacion-uv.png);
}
.ahorro-de-energia .nombre-servicio:after {
    background-image: url(../img/ahorro-de-energia.png);
}
.calibraciones .nombre-servicio:after {
    background-image: url(../img/calibraciones.png);
}

.descripcion {
    // position: fixed;
    // // vertical-align: middle;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    z-index: 30;
}
.calendario {
    @include m.resetear-lista;
    li {
        color: v.$blanco;
        padding: 2.5rem;
        font-weight: 700;
        span {
            font-weight: 400;
        }
    }
}
.oculto {
    margin: 0;
    height: 0;
    visibility: hidden;
}
.expuesto {
    width: auto;
    height: 10rem;
    visibility: visible;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: rgba(0 0 0/ .5);
    picture{
        display: contents;
        max-width: 80rem;
        img {
            max-width: 90%;
            position: fixed;
        }
        @include m.tablet {
            img {
                max-width: 70%;
                position: fixed;
            }
        }
        @include m.desktop {
            img {
                max-width: 50%;
                position: fixed;
            }
        }
        
    }
    p {
        z-index: 20;
    }
}
.bg-grisoscuro {
    background-color: v.$grismasoscuro;

    li:nth-child(odd) {
        background-color: darken(v.$negro, 20%);
    }
}
.bg-verde {
    background-color: v.$grismasoscuro;
    li:nth-child(odd) {
        background-color: darken(v.$verde, 10%);
    }
}