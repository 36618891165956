@use 'base/variables' as v;
@use 'base/mixins' as m;

.sobre-kelvin {
    max-width: 80rem;
    padding: 3rem 0;
    background-color: v.$negro;
    // @include m.tablet {
    //     @include m.grid(2, 5rem);
    // }
}

.contenido-kelvin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    @include m.tablet {
        margin-top: 0;
    }
    h3 {
        margin-bottom: 2rem;
        color: v.$blanco;
    }
    p {
        color: v.$blanco;
        font-size: 2.5rem;
    }
}
//     .fecha {
//         color: v.$rosa;
//         font-weight: 700;
//     }
// }