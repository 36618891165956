@use 'base/variables' as v;
@use 'base/mixins' as m;

.clientes {
    background-color: v.$grisoscuro;
    padding: 5rem 5rem;
        
    h3{
        color: v.$blanco;
        text-align: center;
    }
    picture {
        display: flex;
        justify-content: center;
    }
}
img {
    max-width: 40%;
    min-width: 230px;
    @include m.tablet{
        max-width: 50%;
        min-width: 130px;
    }
    @include m.desktop {
        max-width: 60%;
        // min-width: 180px;
    }
}
.clientes-imagenes {
    @include m.resetear-lista;
    @include m.grid(1, 4rem);
        @include m.telefono {
        @include m.grid(1, 4rem);
    }
    @include m.tablet{
        @include m.grid(2, 4rem);
    }
    @include m.desktop {
        @include m.grid (4, 4rem);
    }
}

// .overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100%;
//     height: 100%;
//     display: grid;
//     place-items: center;
//     background-color: rgba(0 0 0/ .5);
//     picture{
//         display: block;
//         max-width: 80rem;
//     }
// }

.btn-cerrar {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: v.$blanco;
    color: v.$negro;
    display: grid;
    place-items: center;
    font-weight: 700;
    &:hover {
        cursor: pointer;
    }
}