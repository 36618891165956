@use 'base/variables' as v;
@use 'base/mixins' as m;

.datos {
    padding: 5rem 0;
    h3 {
        text-align: center;
        color: v.$blanco;
        margin-bottom: 2rem;
    }
    .contenedor-datos {
        @include m.tablet {
            @include m.grid(2, 5rem);
        }
    }
}
// #map { height: 280px; }
// <style>
  #map { 
    width: 100%;
    height: 320px;
    // box-shadow: 5px 5px 5px #888;
 }
// </style>
.contacto {
    color: v.$blanco;
    .nombre-contacto {
        text-align: center;
        font-size: 2.8rem;
        color: v.$blanco;
    }
    &-header, &-footer {
        padding: .5rem;
        p {
            text-align: center;
            color: v.$blanco;
            margin: 0;
        }
    }
    &-header {
        background-color: v.$grismasoscuro;
        p {
            text-transform: uppercase;
            font-size: 1.6rem;
        }
    }
    .contacto-body {
        background-color: v.$grismasoscuro;
        ul {
            @include m.resetear-lista;
            li, :nth-child(odd) {
                background-color: v.$grismasoscuro;
                padding: 1rem;
                border-bottom: 1px solid #e1e1e1;
            }
        }
    }
    .contacto-footer {
        display: grid;
        background-color: v.$grismasoscuro;
        justify-content: center;
        justify-items: center;
        p {
            font-size: 1.4rem;
            font-weight: normal;
        }
        picture {
            display: flex;
            justify-content: center;
            max-width: 50%;
    }
    }
}