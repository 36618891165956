// Tipografia
$fuente_principal: 'Montserrat', sans-serif;

// Colores
$grismasoscuro: #353334;
$amarillo: #ffcc29;
$morado: #752f97;
$negro: #000000;
$blanco: #fff;
$grisoscuro: #4C4C4D;
$naranjaclaro: #FF7B00;
$rojo: #FF0000;
$verde: #0F9E54;
$verdeclaro: #a8cf45;



// Tamaño de media queries
$telefono: 480px;
$tablet: 768px;
$desktop: 992px;
$desktopXL: 1200px;