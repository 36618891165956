@use 'base/variables' as v;
@use 'base/mixins' as m;

.video {
    position: relative;
    max-height: 70rem;
    min-height: 50rem;
    overflow: hidden;
    .overlay {
        position: absolute;
        background: rgb(122, 119, 124);
        background: linear-gradient(270deg, rgba(116, 113, 117, 0.5) 0%, rgba(90, 84, 87, 0.3) 100%);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    video {
        width: auto;
        display: block;
        @include m.tablet {
           width: 100%;
        }
    }
}
.contenido-video {
    z-index: 10;
    h1 {
        color: v.$blanco;
        font-size: 7rem;
        margin: 0;
        text-align: center;
    }
    p {
        // background-color: v.$verde;
        // display: inline-block;
        padding: 1rem 2rem;
        color: v.$blanco;
        font-weight: 700;
        font-size: 2.4rem;
        text-align: center;
    }
}