@use 'base/variables' as v;
@use 'base/mixins' as m;

.header {
    z-index: 5;
    background-color: v.$grisoscuro;
    @include m.tablet{
        &.fijo {
            left: 0;
            top: 0;
            position: fixed;
            width: 100%;
            box-shadow: .1rem .2rem .3rem #666;
        }
    }
    
    .logo {
        display: flex;
        justify-content: center;
        img {
            max-width: 28rem;
        } 
        @include m.tablet {
            align-items: center;
            margin: 0;
        }
    }
    .contenido-header {
        padding: 0.5rem;
        @include m.tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.navegacion-principal {
  @include m.tablet {
    display: flex;
    gap: 2rem;
  }
    img {
        display: flex;
        text-align: center;
    }
    a {
        display: block;
        text-align: center;
        color: v.$blanco;
        font-size: 2.1rem;
        margin-bottom: 1rem;
        @include m.tablet {
            margin: 0;
        }
        &:hover {
            color: v.$amarillo;
        }
    }
    
}

// .prueba {
//     @include m.heading(v.$amarillo);
// }
// p {
//     @include m.heading;
// }